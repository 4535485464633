/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Contact from "src/elements/Contact";
import Layout from "../components/Layout";
import Footer from "src/elements/Footer";
import Form from "src/elements/pages/signup/Form";
import { PageReset, StyledPageContainer } from "../components/helpers";

const SlackInvitePage = () => (
  <Layout header={null} footer={<Footer />}>
    <PageReset />
    <StyledPageContainer>
      <Form />
      <Contact />
    </StyledPageContainer>
  </Layout>
);

export default SlackInvitePage;
